<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> /        
      </template>
      <template slot="area-titulo">
        <h4>{{ item.titulo }}</h4>        
      </template>
    </titulo-seccion>

    <div id="content-page" class="container">
      <div class="row">
        <div class="col-md-12">
          <div v-if="item.imagen" class="img-portada lg-round" >
            <img v-lazy="item.imagen" class="img-fluid img-responsive" :alt="item.titulo" :title="item.titulo">                
          </div>
          <div class="mt-4">
            <!-- <h3 class="titulo" data-aos="fade-up">{{ item.titulo }}</h3> -->
            <div class="mt-2" data-aos="fade-up" v-html="item.contenido">

            </div>            
          </div>
        </div>
      </div>      
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import TituloSeccion from './secciones/TituloSeccion.vue'

export default {
  components: {
    TituloSeccion,
  },
  data() {
    return {
      item: [],
      preview:'',
    };
  },
  metaInfo () {
    return {
      title: 'Ministerio Público',
      htmlAttrs: {
        lang: 'es-ES'
      },
      titleTemplate: '%s | '+this.item.titulo,
      meta: [
        { vmid: 'description', name: 'description', content: this.item.titulo }
      ]
    }
  },
  mounted() {
    if (this.$route.params.slug) {
      if (this.$route.query.preview) {
        this.preview=this.$route.query.preview;
      }
      this.getPagina(this.$route.params.slug);      
    }
  },
  watch: {
    $route: function (val) {
      if (val.params.slug) {
        this.getPagina(this.$route.params.slug);
      }
    },
  },
  methods: {
    getPagina(slug){
      var url= '/web/pagina/' + slug+'?preview='+this.preview;
        axiosIns.get(url)
        .then(res => {
            if (res.data) {
              this.item = res.data;  
            }else{
              this.$router.push({ name: "error-404" });
            }          
        })
        .catch(err =>{
            console.log(err);
        });
    },    
  },
}
</script>

<style>
  
</style>
